"use client";
import React from 'react';
import dynamic from 'next/dynamic';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slider = dynamic(() => import('react-slick'));

function ClientTestimonial({testimonials}:any) {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {
          testimonials && testimonials.map((testimonial:any,index:any)=>{
            return (
              <div key={index} className="item">
                <div className="testi_content" dangerouslySetInnerHTML={{ __html: testimonial?.node?.content || ''}}></div>
                <h3>{testimonial?.node?.title} <span>{testimonial?.node?.testimonial?.positionCompanyName}</span> </h3>
              </div>
            )
          })
        }
      </Slider>
    </div>
  )
}

export default ClientTestimonial
